import React from 'react';
import { StyledDropdown } from './styles';
import { FiMapPin, FiTruck, FiUser } from 'react-icons/fi';

const Dropdown = ({ icon, placeholder, options, onChange }) => {
  return (
    <StyledDropdown>
      {icon == 'pin' && <FiMapPin color='#02abde' />}
      {icon == 'user' && <FiUser color='#02abde' />}
      {icon == 'truck' && <FiTruck color='#02abde' />}
      <select onChange={onChange}>
        <option value={0}>{placeholder}</option>
        {options &&
          options.map((option) => {
            return (
              <option key={option.key} value={option.value}>
                {option.key}
              </option>
            );
          })}
      </select>
    </StyledDropdown>
  );
};

export default Dropdown;
