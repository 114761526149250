import React, { useState, useEffect } from 'react';
import InputField from '../InputField/InputField';
import ButtonSecondary from '../Button/ButtonSecondary';
import { DetailModal, DetailModalContent } from './styles';
import { getProductByName } from '../../api';
import { FiBox } from 'react-icons/fi';
import Button from '../Button/Button';
import './_styles.search-modal.scss';

const SearchModal = ({ visible, onClose, site, onSelect }) => {
  const [searchVal, setSearchVal] = useState('');
  const [resultSearchVal, setResultSearchVal] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const OnSearchChange = async (e) => {
    setSearchVal(e.target.value);
  };

  const OnProductSelect = (barcode, lotId) => {
    document.getElementById('searchInput').value = '';
    setResults([]);
    onSelect(barcode, lotId);
  };

  const search = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResults([]);
    const { data } = await getProductByName(searchVal, site);
    setResults(data);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   const getProds = async () => {
  //     setIsLoading(true);
  //     setResults([]);
  //     const rv = searchVal;
  //     const { data } = await getProductByName(searchVal, site);
  //     console.log(data);
  //     setResultSearchVal(rv);
  //     setResults(data);
  //     setIsLoading(false);
  //   };
  //   if (searchVal != resultSearchVal) {
  //     getProds();
  //   }
  // }, [searchVal, resultSearchVal]);

  return (
    <DetailModal style={{ display: visible ? 'block' : 'none' }}>
      <DetailModalContent
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <form onSubmit={search}>
          <InputField
            placeholder='Search'
            onChange={OnSearchChange}
            id='searchInput'
            customStyle={{ width: '100%' }}
          />
          <div>
            {isLoading && <p>Searching...</p>}
            {!isLoading && results.length == 0 && <p>No results</p>}
            {results.map((product) => {
              return (
                <div
                  className='searchResult'
                  key={Math.random()}
                  onClick={() => {
                    OnProductSelect(product.barcode, product.lot_id);
                  }}
                >
                  <FiBox size={30} color='#02abde' />
                  <div>
                    <p>{product.short_name}</p>
                    <p>Lot: {product.ref_desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='buttons'>
            <Button title='Search' onClick={search} />
            <ButtonSecondary title='Cancel' onClick={onClose} />
          </div>
        </form>
      </DetailModalContent>
    </DetailModal>
  );
};

export default SearchModal;
