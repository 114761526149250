import React from 'react';
import { StyledSelect } from './styles';

const InputField = ({ placeholder, onChange, name, options }) => {
  return (
    <StyledSelect placeholder={placeholder} onChange={onChange} name={name}>
      <option value={0}>{placeholder}</option>
      {options &&
        options.map((option) => {
          return (
            <option key={option.key} value={option.value}>
              {option.key}
            </option>
          );
        })}
    </StyledSelect>
  );
};

export default InputField;
