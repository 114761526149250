import React, { useState, useEffect } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import { Screen } from './styles';
import ProductTable from '../../components/ProductTable/ProductTable';
import { getSites, getParties, createReceipt, addLot } from '../../api';

const ReceiptsScreen = () => {
  const [sites, setSites] = useState([]);
  const [parties, setParties] = useState([]);
  const [selectedSite, setSelectedSite] = useState(0);
  const [selectedSupplier, setSelectedSupplier] = useState(0);

  useEffect(() => {
    getSites(JSON.parse(localStorage.getItem('profile')).partyId).then(
      ({ data }) => {
        console.log(data);
        setSites(
          data.map((site) => {
            return {
              key: site.name,
              value: site.id
            };
          })
        );
      }
    );

    getParties(true).then(({ data }) => {
      console.log(data);
      setParties(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  }, []);

  const receive = async (products, callback) => {
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const lot = await addLot({
        product_id: product.product_id,
        lot_desc: product.lot_desc,
        barcode: product.barcode,
        mfg_date: product.mfg_date,
        exp_date: product.exp_date
      });

      products[i].lotId = lot.data.id;
    }

    var quantity = 0;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      quantity += parseInt(product.quantity);
    }

    const requestData = {
      user_id: JSON.parse(localStorage.getItem('profile')).userId,
      supplier_id: selectedSupplier,
      to_site_id: selectedSite,
      quantity: quantity,
      products: products.map((res) => {
        return { id: res.product_id, lot: res.lotId, quantity: res.quantity };
      })
    };

    const { data } = await createReceipt(requestData);

    if (data) {
      alert(data.message);
      callback(data.success);
    } else {
      callback(false);
    }
  };

  const selectSite = (e) => {
    setSelectedSite(e.target.value);
  };

  const selectSupplier = (e) => {
    setSelectedSupplier(e.target.value);
  };

  return (
    <>
      <Screen>
        <div className='dropdowns' style={{ width: '90%' }}>
          <Dropdown
            placeholder='Select a site'
            icon='pin'
            options={sites}
            onChange={selectSite}
          />
          <Dropdown
            placeholder='Select a supplier'
            icon='user'
            options={parties}
            onChange={selectSupplier}
          />
        </div>
        <ProductTable
          canAddProduct={selectedSupplier != 0 && selectedSite != 0}
          onDone={receive}
        />
      </Screen>
    </>
  );
};

export default ReceiptsScreen;
