import styled from 'styled-components';

export const StyledDropdown = styled.div`
  border: 1px solid #d7e3ec;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;

  select {
    border: none;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    padding: 0.75rem;
  }
`;
