import React from 'react';
import { StyledDropdown } from './styles';
import { FiMapPin, FiTruck, FiUser, FiCalendar } from 'react-icons/fi';

const IndentedDropdown = ({
  icon,
  placeholder,
  options,
  onChange,
  style,
  value = -1,
}) => {
  return (
    <StyledDropdown style={style}>
      {icon == 'pin' && <FiMapPin color='#02abde' />}
      {icon == 'user' && <FiUser color='#02abde' />}
      {icon == 'truck' && <FiTruck color='#02abde' />}
      {icon == 'calendar' && <FiCalendar color='#02abde' />}
      <select onChange={onChange} value={value}>
        <option value={-1}>{placeholder}</option>
        {options &&
          options.map((option) => {
            return (
              <option key={option.key} value={option.value}>
                {option.key}
              </option>
            );
          })}
      </select>
    </StyledDropdown>
  );
};

export default IndentedDropdown;
