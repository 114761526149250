import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fdfdfd;
  border-radius: 8px;
  transition: 0.3s;
  border: none;
  border: 1px solid #d7e3ec;
  display: flex;
  flex-direction: column;
`;

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf2f5;

  > div:last-child {
    display: flex;
    flex-direction: row;
  }
`;
