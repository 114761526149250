import React, { useState, useEffect } from 'react';
import ManageTable from '../../components/ManageTable/ManageTable';
import SideBar from '../../components/SideBar/SideBar';
import * as Data from './ManageData';
import { getManagementData } from '../../api';
import Button from '../../components/Button/Button';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './_styles.manage.scss';
import ManageExpiringStock from './Screens/ManageExpiringStock/ManageExpiringStock';

const ManageScreen = () => {
  const { activeTab } = useParams();
  const navigate = useNavigate();
  const [headers, setHeaders] = useState(Data.productHeaders);
  const [tableData, setTableData] = useState(Data.productHeaders);
  const [addSentence, setAddSentence] = useState('Add Product');
  const [search, setSearch] = useState('');
  const userData = JSON.parse(localStorage.getItem('profile'));

  useEffect(() => {
    setTableData([]);
    let tab = 'products';
    switch (activeTab) {
      case 'parties':
        tab = 'parties';
        setHeaders(Data.partyHeaders);
        setAddSentence('Add Party');
        break;
      case 'products':
        tab = 'products';
        setHeaders(Data.productHeaders);
        setAddSentence('Add Product');
        break;
      case 'sites':
        tab = 'sites';
        setHeaders(Data.siteHeaders);
        setAddSentence('Add Site');
        break;
      case 'users':
        tab = 'users';
        setHeaders(Data.userHeaders);
        setAddSentence('Add User');
        break;
      case 'templates':
        tab = 'templates';
        setHeaders(Data.priceHeaders);
        setAddSentence('Add Template');
        break;
      case 'expiring':
        tab = 'expiring';
        setHeaders(Data.priceHeaders);
        setAddSentence('Add Template');
        break;
      default:
        break;
    }

    const getData = async () => {
      if (activeTab === 'expiring') return;
      const { data } = await getManagementData(tab);
      setTableData(data);
    };

    getData();
  }, [activeTab]);

  return (
    <>
      <div className='screen'>
        <div className='tab-screen'>
          <div className='tabs-header'>
            <div
              className={`tab ${
                activeTab == 'products' || !activeTab ? 'active' : null
              }`}
              onClick={() => navigate('/manage/products')}
            >
              Products
            </div>
            <div
              className={`tab ${activeTab == 'parties' ? 'active' : null}`}
              onClick={() => navigate('/manage/parties')}
            >
              Parties
            </div>
            <div
              className={`tab ${activeTab == 'sites' ? 'active' : null}`}
              onClick={() => navigate('/manage/sites')}
            >
              Sites
            </div>
            {userData && userData.role == 'administrator' && (
              <div
                className={`tab ${activeTab == 'users' ? 'active' : null}`}
                onClick={() => navigate('/manage/users')}
              >
                Users
              </div>
            )}
            <div
              className={`tab ${activeTab == 'templates' ? 'active' : null}`}
              onClick={() => navigate('/manage/templates')}
            >
              Price Templates
            </div>
            <div
              className={`tab ${activeTab == 'expiring' ? 'active' : null}`}
              onClick={() => navigate('/manage/expiring')}
            >
              Expiring Stock
            </div>
          </div>
          {activeTab === 'expiring' ? (
            <ManageExpiringStock />
          ) : (
            <>
              <div className='tabs-footer'>
                <div className='search'>
                  <FaMagnifyingGlass />
                  <input
                    type='text'
                    placeholder='Search'
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                <Link to={!activeTab ? 'products/add' : 'add'}>
                  <Button title={addSentence} />
                </Link>
              </div>
              <ManageTable
                headers={headers}
                data={tableData}
                addSentence={addSentence}
                search={search}
                link={activeTab}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ManageScreen;
