import React from 'react';
import './_styles.button.scss';

const ButtonSecondary = ({ title, type, disabled, onClick }) => {
  return (
    <button
      className='button-secondary'
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default ButtonSecondary;
