export const productHeaders = [
  'Long Name',
  'Short Name',
  'Barcode',
  'NAPPI Code',
  'Supplier',
];
export const partyHeaders = [
  'Name',
  'Account Code',
  'VAT No',
  'Reg No',
  'Customer',
];
export const siteHeaders = ['Name', 'Address 1', 'Country Code', 'Owner'];
export const userHeaders = ['First Name', 'Last Name', 'Email', 'Phone'];
export const priceHeaders = ['Name'];
