import React, { useState, useEffect } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import { Screen } from './styles';
import Dropdown from '../../components/Dropdown/Dropdown';
import ProductTable from '../../components/ProductTable/ProductTable';
import '../../App.css';
import { getSites, getParties, createSale, addLot } from '../../api';

const SellScreen = () => {
  const [sites, setSites] = useState([]);
  const [customerSites, setCustomerSites] = useState([]);
  const [parties, setParties] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(0);
  const [transferType, setTransferType] = useState(0);
  const [selectedSiteFrom, setSelectedSiteFrom] = useState(0);
  const [selectedSiteTo, setSelectedSiteTo] = useState(0);

  useEffect(() => {
    getSites(JSON.parse(localStorage.getItem('profile')).partyId).then(
      ({ data }) => {
        console.log(data);
        setSites(
          data.map((site) => {
            return {
              key: site.name,
              value: site.id
            };
          })
        );
      }
    );

    getParties(null, true).then(({ data }) => {
      console.log(data);
      setParties(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  }, []);

  const selectType = (e) => {
    setTransferType(e.target.value);
  };

  const selectCustomer = (e) => {
    setSelectedCustomer(e.target.value);
    getSites(e.target.value).then(({ data }) => {
      console.log(data);
      setCustomerSites(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  };

  const selectSiteFrom = (e) => {
    setSelectedSiteFrom(e.target.value);
  };

  const selectSaleSite = (e) => {
    setSelectedSiteFrom(e.target.value);
    setSelectedSiteTo(e.target.value);
  };

  const selectSiteTo = (e) => {
    setSelectedSiteTo(e.target.value);
  };

  const canAddProduct = () => {
    return selectedSiteFrom != 0 && selectedSiteTo != 0;
  };

  const onSubmit = async (products, callback) => {
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const lot = await addLot({
        product_id: product.product_id,
        lot_desc: product.lot_desc,
        barcode: product.barcode,
        mfg_date: product.mfg_date,
        exp_date: product.exp_date
      });

      products[i].lotId = lot.data.id;
    }

    var quantity = 0;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      quantity += parseInt(product.quantity);
    }

    const requestData = {
      user_id: JSON.parse(localStorage.getItem('profile')).userId,
      type_id: transferType,
      from_site_id: selectedSiteFrom,
      to_site_id: selectedSiteTo,
      to_party_id: selectedCustomer,
      total_amount: quantity,
      products: products.map((res) => {
        return {
          id: res.product_id,
          lot: res.lotId,
          quantity: res.quantity,
          price: res.price,
          delivery_note: res.delivery_note
        };
      })
    };

    const { data } = await createSale(requestData);

    if (data) {
      alert(data.message);
      callback(data.success);
    } else {
      callback(false);
    }
  };

  return (
    <>
      <Screen>
        <div className='dropdowns' style={{ width: '90%' }}>
          <Dropdown
            placeholder='Select a type'
            icon='truck'
            options={[
              { key: 'Consignment', value: 1 },
              { key: 'Direct', value: 2 }
            ]}
            onChange={selectType}
          />
          <Dropdown
            placeholder='Select a Customer'
            icon='user'
            options={parties}
            onChange={selectCustomer}
          />
          {transferType == 2 && selectedCustomer != 0 && (
            <Dropdown
              placeholder='Select a site to transfer from'
              icon='pin'
              options={sites}
              onChange={selectSiteFrom}
            />
          )}
          {transferType == 1 && selectedCustomer != 0 && (
            <Dropdown
              placeholder='Select a Sale Site'
              icon='pin'
              options={customerSites}
              onChange={selectSaleSite}
            />
          )}
          {transferType == 2 && selectedCustomer != 0 && (
            <Dropdown
              placeholder='Select a site to transfer to'
              icon='pin'
              options={customerSites}
              onChange={selectSiteTo}
            />
          )}
        </div>
        <ProductTable
          canAddProduct={canAddProduct()}
          isSale={true}
          customerId={selectedCustomer}
          onDone={onSubmit}
          site={selectedSiteFrom}
          checkStock={true}
        />
      </Screen>
    </>
  );
};

export default SellScreen;
