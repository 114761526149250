import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import { PTable, InputLabel } from './styles';
import { getExpiringReport, getRecallReport } from '../../../api';
import ButtonSecondary from '../../../components/Button/ButtonSecondary';

const RecallScreen = () => {
  const [searchVal, setSearchVal] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = async () => {
    const { data } = await getRecallReport(searchVal, true);

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `Recall Report ${searchVal}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getReport = async () => {
    setIsLoading(true);
    const { data } = await getRecallReport(searchVal);
    setTableData(data ? data.data : []);
    setIsLoading(false);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '10px 0',
          backgroundColor: '#f5f5f5',
          padding: '10px 0',
          borderRadius: 18,
        }}
      >
        <InputLabel>
          <label>Product or Lot recalled</label>
          <InputField
            type='text'
            placeholder='Product or Lot recalled'
            customStyle={{
              minWidth: 250,
              textAlign: 'left',
              padding: `17px 20px`,
            }}
            onChange={(val) => setSearchVal(val.target.value)}
          />
        </InputLabel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0 10px',
        }}
      >
        <Button title='Generate' onClick={() => getReport()} />
        <ButtonSecondary
          title='Download .csv'
          onClick={() => downloadReport()}
        />
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <PTable>
          <table>
            <thead>
              <tr>
                <th>Report Date</th>
                <th>Type</th>
                <th>Transaction Date</th>
                <th>Delivery Note</th>
                <th>Product</th>
                <th>Product Description</th>
                <th>Lot</th>
                <th>Site Name</th>
                <th>Site Owner</th>
                <th>Expiry Date</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => {
                return (
                  <tr>
                    <td>{row['Report Date']}</td>
                    <td>{row['Type']}</td>
                    <td>{row['Transaction Date']}</td>
                    <td>{row['Delivery Note']}</td>
                    <td>{row['Product']}</td>
                    <td>{row['Product Desc']}</td>
                    <td>{row['Lot']}</td>
                    <td>{row['Site Name']}</td>
                    <td>{row['Site Owner']}</td>
                    <td>{row['Expiry Date']}</td>
                    <td>{row['Quantity']}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </PTable>
      )}
    </>
  );
};

export default RecallScreen;
