import React from 'react';
import SideBar from '../../components/SideBar/SideBar';
import { Screen, TabScreen, Tab } from './styles';
import { Route, Link, useParams } from 'react-router-dom';
import ExpiringScreen from './ReportTypes/Expiring';
import SalesScreen from './ReportTypes/Sales';
import StockTakeScreen from './ReportTypes/StockTake';
import StockOnHandScreen from './ReportTypes/StockOnHand';
import RecallScreen from './ReportTypes/Recall';

const ReportsScreen = () => {
  const { reportType } = useParams();

  return (
    <>
      <Screen>
        <TabScreen>
          <div className='tabsHeader'>
            <Link to={`/reports/sales`}>
              <Tab
                className={
                  window.location.pathname == `/reports/sales` ||
                  window.location.pathname == `/reports`
                    ? 'active'
                    : ''
                }
              >
                Sales
              </Tab>
            </Link>
            <Link to={`/reports/stocktake`}>
              <Tab
                className={
                  window.location.pathname == `/reports/stocktake`
                    ? 'active'
                    : ''
                }
              >
                Stock Take
              </Tab>
            </Link>
            <Link to={`/reports/stockonhand`}>
              <Tab
                className={
                  window.location.pathname == `/reports/stockonhand`
                    ? 'active'
                    : ''
                }
              >
                Stock On Hand
              </Tab>
            </Link>
            <Link to={`/reports/expiring`}>
              <Tab
                className={
                  window.location.pathname == `/reports/expiring`
                    ? 'active'
                    : ''
                }
              >
                Expiring
              </Tab>
            </Link>
            <Link to={`/reports/recall`}>
              <Tab
                className={
                  window.location.pathname == `/reports/recall` ? 'active' : ''
                }
              >
                Recall
              </Tab>
            </Link>
          </div>
          {(reportType === 'sales' || !reportType) && <SalesScreen />}
          {reportType === 'stocktake' && <StockTakeScreen />}
          {reportType === 'stockonhand' && <StockOnHandScreen />}
          {reportType === 'expiring' && <ExpiringScreen />}
          {reportType === 'recall' && <RecallScreen />}
        </TabScreen>
      </Screen>
    </>
  );
};

export default ReportsScreen;
