import React, { useState, useEffect } from 'react';
import InputField from '../InputField/InputField';
import ButtonSecondary from '../Button/ButtonSecondary';
import { DetailModal, DetailModalContent } from './styles';
import { getProduct, productStockCheck } from '../../api';
import Button from '../Button/Button';
import './_styles.product-modal.scss';

const initialValue = {
  product_id: '',
  supplier_name: '',
  product_name: '',
  lot_desc: '',
  exp_date: '',
  mfg_date: '',
  quantity: '',
  price: '',
  barcode: '',
  delivery_note: ''
};

const ProductModal = ({
  visible,
  onClose,
  site,
  barcode,
  customerId,
  onAdd,
  isSale,
  isTransfer,
  lotId,
  checkStock = false,
  editData,
  onSave
}) => {
  const [results, setResults] = useState(initialValue);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getProduct('man-' + barcode, customerId, lotId);
      if (data.found) {
        var formattedData = {
          product_id: data.id,
          supplier_name: data.Party.nickname,
          product_name: data.short_name,
          lot_desc: data.lot_desc,
          exp_date: data.exp_date,
          mfg_date: data.mfg_date,
          barcode: data.barcode,
          delivery_note: data.delivery_note,
          quantity: '1',
          price: data.price ?? 0,
          lot_id: lotId
        };

        setResults(formattedData);
      }
    };

    if (barcode) {
      fetch();
    }
  }, [barcode]);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(editData);
    setResults(editData ? editData : initialValue);
  }, [editData]);

  const handleAdd = async (e) => {
    if (checkStock) {
      const { data } = await productStockCheck(site, results.product_id, lotId);
      if (data.amount < results.quantity) {
        alert(`Quantity is more than available stock count. (${data.amount})`);
        return;
      }
      onAdd(results);
      setResults(initialValue);
    } else {
      onAdd(results);
      setResults(initialValue);
    }
  };

  const Cancel = () => {
    setResults(initialValue);
    onClose();
  };

  const handleSave = async () => {
    if (checkStock) {
      const { data } = await productStockCheck(site, results.product_id, lotId);
      if (data.amount < results.quantity) {
        alert(`Quantity is more than available stock count. (${data.amount})`);
        return;
      }
      onSave(results);
      setResults(initialValue);
    } else {
      onSave(results);
      setResults(initialValue);
    }
  };

  return (
    <DetailModal style={{ display: visible ? 'block' : 'none' }}>
      <DetailModalContent
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='detailInput'>
          <label>Supplier</label>
          <InputField
            name='supplier_name'
            placeholder='Supplier'
            onChange={handleChange}
            value={results.supplier_name}
            disabled={true}
          />
        </div>
        <div className='detailInput'>
          <label>Product</label>
          <InputField
            name='product_name'
            placeholder='Product'
            onChange={handleChange}
            value={results.product_name}
            disabled={true}
          />
        </div>
        <div className='detailInput'>
          <label>Lot</label>
          <InputField
            name='lot_desc'
            placeholder='Lot'
            onChange={handleChange}
            value={results.lot_desc}
          />
        </div>
        <div className='detailInput'>
          <label>Expiry Date</label>
          <InputField
            name='exp_date'
            placeholder='dd/MM/yyyy'
            onChange={handleChange}
            value={results.exp_date}
            type='date'
          />
        </div>
        <div className='detailInput'>
          <label>Manufactured Date</label>
          <InputField
            name='mfg_date'
            placeholder='Manufactured Date'
            onChange={handleChange}
            value={results.mfg_date}
            type='date'
          />
        </div>
        {(isSale || isTransfer) && (
          <div className='detailInput'>
            <label>{isSale ? 'Invoice Number' : 'Delivery Note'}</label>
            <InputField
              name='delivery_note'
              placeholder={isSale ? 'Invoice Number' : 'Delivery Note'}
              onChange={handleChange}
              value={results.delivery_note}
            />
          </div>
        )}
        <div className='detailInput'>
          <label>Quantity</label>
          <InputField
            name='quantity'
            placeholder='Quantity'
            onChange={handleChange}
            value={results.quantity}
            type='number'
          />
        </div>
        {isSale && (
          <div className='detailInput'>
            <label>Price</label>
            <InputField
              name='price'
              placeholder='Price'
              onChange={handleChange}
              value={results.price}
              type='number'
            />
          </div>
        )}
        <div className='buttons'>
          <ButtonSecondary title='Cancel' onClick={Cancel} />
          <Button
            title={editData ? 'Save' : 'Add'}
            onClick={editData ? handleSave : handleAdd}
          />
        </div>
      </DetailModalContent>
    </DetailModal>
  );
};

export default ProductModal;
