import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './_styles.manage-table.scss';

const getColumnWidth = (header) => {
  switch (header) {
    case 'Country Code':
      return 100;
    default:
      return null;
  }
};

const ManageTable = ({ headers = [], data, search, link }) => {
  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    let show = [];

    let filterKeys = headers.map((header) =>
      header.toLowerCase().replace(' ', '_')
    );

    if (search) {
      show = data.filter((val) => {
        return filterKeys.some((key) => {
          return val[key]?.toLowerCase().includes(search.toLowerCase());
        });
      });
    } else {
      show = data;
    }

    setDataToShow(show);
  }, [search, data]);

  return (
    <div className='manage-table'>
      <table>
        <thead>
          <tr>
            {headers.map((header) => {
              return <th>{header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataToShow.map((entry) => {
            return (
              <tr>
                {headers.map((key) => {
                  const isFirst = headers.indexOf(key) == 0;
                  var prop = key.toLowerCase().replace(' ', '_');

                  return isFirst ? (
                    <td title={entry[prop]}>
                      <Link to={`/manage/${link}/edit?id=${entry.id}`}>
                        {entry[prop]}
                      </Link>
                    </td>
                  ) : (
                    <td title={entry[prop]} width={getColumnWidth(key)}>
                      <p>{entry[prop]}</p>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManageTable;
