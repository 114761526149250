const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const GetSalesData = (annualSales, projectedSales, stockOnHand) => {
  let labels = [];

  let projectedData = [];

  for (let i = 0; i < annualSales.length; i++) {
    const sale = annualSales[i];
    labels.push(months[sale.month - 1] + ' ' + sale.year);
    if (i == annualSales.length - 1) {
      projectedData.push(parseFloat(projectedSales));
    } else {
      projectedData.push(0);
    }
  }

  return {
    labels: labels,
    datasets: [
      {
        type: 'line',
        label: 'Stock',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 2,
        fill: false,
        data: stockOnHand.map((sale) => sale.StockOnHand),
      },
      {
        type: 'bar',
        label: 'Sales',
        backgroundColor: '#02abde',
        data: annualSales.map((sale) => sale.totalSales),
        borderColor: 'white',
        borderWidth: 2,
      },
      {
        type: 'bar',
        label: 'Projected Sales',
        backgroundColor: 'rgba(75, 192, 192, 1)',
        data: projectedData,
        borderColor: 'white',
        borderWidth: 2,
      },
    ],
  };
};

export const topClients = (clientData, colors) => {
  const labels = [];
  const data = [];

  clientData.sort(function (a, b) {
    var keyA = new Date(a.totalSales),
      keyB = new Date(b.totalSales);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  clientData.map((cd) => {
    labels.push(cd.Client);
    data.push(cd.totalSales);
  });

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
      },
    ],
  };
};

export const topSites = (siteData, clientData, colors) => {
  const labels = [];
  const data = [];
  const dataColors = [];
  const otherColors = [
    'rgba(255, 159, 64, 1)',
    '#edf100',
    '#eb3636',
    '#ff56e8',
    '#4b4dc0',
    '#85ff66',
  ];

  clientData.sort(function (a, b) {
    var keyA = new Date(a.totalSales),
      keyB = new Date(b.totalSales);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  for (let i = 0; i < siteData.length; i++) {
    const cd = siteData[i];
    labels.push(cd.Site);
    data.push(cd.totalSales);

    const clientIndex = clientData.findIndex((x) => x.Client == cd.Client);
    if (clientIndex < 0) {
      const rndInt = Math.floor(Math.random() * 5);
      dataColors.push(otherColors[rndInt]);
    } else {
      dataColors.push(colors[clientIndex]);
    }
  }

  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: dataColors,
      },
    ],
  };
};
