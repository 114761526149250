import styled from 'styled-components';

// export const PTable = styled.table`
//   width: 95%;
//   background-color: white;
//   border-radius: 12px;
//   margin: 20px 0;
//   padding: 20px;
//   box-shadow: 0 10px 15px #205c8030;

//   .header {
//     width: 100%;
//     display: flex;
//     justify-content: space-around;
//     text-align: left;
//     font-weight: 700;
//     border-bottom: solid 3px #c0c0c0;
//     margin-bottom: 20px;
//   }

//   .add-product {
//     width: 100%;
//     background-color: #e9e9e9;
//     border: solid 3px #c0c0c0;
//     border-radius: 12px;
//     text-align: center;
//     color: #c0c0c0;
//     font-weight: 700;
//     cursor: pointer;

//     &:disabled {
//       opacity: 0.4;
//       cursor: not-allowed;
//     }
//   }
// `;

export const PTable = styled.div`
  background-color: white;
  width: 100%;
  margin: 40px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  padding: 10px;
  border: 1px solid #d7e3ec;

  *:focus {
    outline: none !important;
  }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    thead {
      background-color: #f3f7f9;
      position: sticky;
      top: 0;
    }

    tr {
      transition: 0.3s;

      &:hover {
        background-color: #f3f7f9;
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid #f3f7f9;
        }
      }
    }

    th {
      border-bottom: 2px solid #f3f7f9;
      padding: 1rem;
    }

    td {
      padding: 1rem;

      text-overflow: ellipsis;

      overflow: hidden;
      white-space: nowrap;

      &:nth-child(even) {
        background-color: rgba(#438cb1, 0.05);
      }
    }
  }

  .add-product {
    width: 100%;
    background-color: #e9e9e9;
    border: solid 3px #c0c0c0;
    border-radius: 12px;
    text-align: center;
    color: #c0c0c0;
    font-weight: 700;
    cursor: pointer;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;
