import styled from 'styled-components';

export const PTable = styled.div`
  background-color: white;
  margin: 0px 0 10px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  padding: 10px;

  *:focus {
    outline: none !important;
  }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    thead {
      background-color: #f3f7f9;
    }

    tr {
      transition: 0.3s;
      &:hover {
        background-color: #f3f7f9;
      }
    }

    th,
    td {
      padding: 15px;
    }
  }

  .add-product {
    width: 100%;
    background-color: #e9e9e9;
    border: solid 3px #c0c0c0;
    border-radius: 12px;
    text-align: center;
    color: #c0c0c0;
    font-weight: 700;
    cursor: pointer;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 10px;

  label {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  input {
    margin: 0;
  }
  select {
    margin: 0 !important;
  }
`;
