import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button/Button';
import IndentedDropdown from '../../../components/IndentedDropdown/IndentedDropdown';
import InputField from '../../../components/InputField/InputField';
import { FiDownload } from 'react-icons/fi';
import { PTable, InputLabel } from './styles';
import {
  getParties,
  getProducts,
  getSalesReport,
  getUsers
} from '../../../api';
import ButtonSecondary from '../../../components/Button/ButtonSecondary';
import Select from 'react-select';

const SalesScreen = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [parties, setParties] = useState([]);
  const [users, setUsers] = useState([]);
  const [party, setParty] = useState();
  const [user, setUser] = useState();
  const [product, setProduct] = useState(-1);

  const productSelected = (e) => {
    setProduct(e.value);
  };

  useEffect(() => {
    getParties(null, true).then(({ data }) => {
      const partiesToSelect = [{ key: 'All', value: 0 }];
      partiesToSelect.push(
        ...data.map((party) => {
          return {
            key: party.name,
            value: party.id
          };
        })
      );
      setParties(partiesToSelect);
    });

    getProducts().then(({ data }) => {
      const productToSelect = [{ label: 'All', value: 0 }];
      productToSelect.push(
        ...data.map((product) => {
          return {
            value: product.id,
            label: product.name
          };
        })
      );
      setProducts(productToSelect);
    });

    getUsers().then(({ data }) => {
      const usersToSelect = [{ key: 'All', value: 0 }];
      usersToSelect.push(
        ...data.map((user) => {
          return {
            key: user.name,
            value: user.id
          };
        })
      );
      setUsers(usersToSelect);
    });
  }, []);

  const getReport = async (download) => {
    if (!fromDate) {
      alert('Please select a date to report from');
      return;
    }

    if (!toDate) {
      alert('Please select a date to report to');
      return;
    }

    if (!user || user < 0) {
      alert('Please select a user');
      return;
    }

    if (!party || party < 0) {
      alert('Please select a client');
      return;
    }

    if (product < 0) {
      alert('Please select a product');
      return;
    }

    const { data } = await getSalesReport(
      fromDate,
      toDate,
      product,
      user,
      party,
      download
    );
    if (download) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Sales Report ${fromDate}-${toDate}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      setTableData(data.data);
      if (data.data.length == 0) {
        alert('No results');
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px 0',
          backgroundColor: '#f5f5f5',
          padding: '10px 0',
          borderRadius: 18
        }}
      >
        <InputLabel>
          <label>From Date</label>
          <InputField
            type='date'
            placeholder='Start Date'
            customStyle={{ minWidth: 250 }}
            onChange={(val) => setFromDate(val.target.value)}
          />
        </InputLabel>
        <InputLabel>
          <label>To Date</label>
          <InputField
            type='date'
            customStyle={{ minWidth: 250 }}
            onChange={(val) => setToDate(val.target.value)}
          />
        </InputLabel>
        <InputLabel>
          <label>Product</label>
          <Select
            options={products}
            placeholder='Search'
            onChange={productSelected}
            styles={{
              control: (styles) => ({
                ...styles,
                minWidth: 250,
                backgroundColor: '#e0e0e0',
                border: 'none',
                borderRadius: 12,
                padding: 5
              })
            }}
          />
        </InputLabel>
        <InputLabel>
          <label>User</label>
          <IndentedDropdown
            placeholder='User'
            icon='user'
            options={users}
            onChange={(e) => setUser(e.target.value)}
            value={user}
          />
        </InputLabel>
        <InputLabel>
          <label>Client</label>
          <IndentedDropdown
            placeholder='Client'
            icon='user'
            options={parties}
            onChange={(e) => setParty(e.target.value)}
            value={party}
          />
        </InputLabel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0 10px'
        }}
      >
        <Button title='Generate' onClick={() => getReport()} />
        <ButtonSecondary
          title='Download .csv'
          onClick={() => getReport(true)}
        />
      </div>

      <PTable>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>Client</th>
              <th>To Site Name</th>
              <th>Product</th>
              <th>Product Description</th>
              <th>Lot</th>
              <th>Expiry Date</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => {
              return (
                <tr>
                  <td>{row.Date}</td>
                  <td>{row.User}</td>
                  <td>{row.Client}</td>
                  <td>{row.ToSiteName}</td>
                  <td>{row.Product}</td>
                  <td>{row['Product Desc']}</td>
                  <td>{row.Lot}</td>
                  <td>{row.ExpiryDate}</td>
                  <td>{row.Quantity}</td>
                  <td>{row.Price}</td>
                  <td>{row.Total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PTable>
    </>
  );
};

export default SalesScreen;
