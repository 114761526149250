import React, { useEffect, useState } from 'react';
import { PTable } from './styles';
import Button from '../Button/Button';
import SearchModal from '../SearchModal/SearchModal';
import ProductModal from '../ProductModal/ProductModal';
import { FiDelete, FiEdit } from 'react-icons/fi';

const ProductTable = ({
  canAddProduct,
  site,
  onDone,
  isSale = false,
  isTransfer = false,
  customerId,
  checkStock
}) => {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [editData, setEditData] = useState(null);

  const addProduct = (data) => {
    setSelectedProduct(null);
    setEditData(null);
    setProducts(
      products.concat({
        ...data,
        key: data.product_id + '_' + data.lot_desc + '_' + Date.now()
      })
    );
  };

  const successCallback = (success) => {
    if (success) {
      setProducts([]);
    } else {
      alert('Something went wrong');
    }
    setIsLoading(false);
  };

  const removeProduct = (id) => {
    setProducts(products.filter((p) => p.key != id));
  };

  const editProduct = (id) => {
    setEditData(products.filter((p) => p.key == id)[0]);
  };

  const updateProduct = (data) => {
    const index = products.findIndex((e) => e.key == data.key);
    var aux = products;
    aux[index] = data;
    setProducts(aux);
    setEditData(null);
  };

  useEffect(() => {
    console.log(products);
  }, [products]);

  return (
    <>
      {/* <Prompt
        when={products.length > 0}
        message='You have an unsubmitted changes. Are you sure you want to cancel?'
      /> */}
      <SearchModal
        visible={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        site={site}
        onSelect={(barcode, lotId) => {
          setShowModal(false);
          setSelectedProduct({ barcode, lotId });
        }}
      />
      <ProductModal
        visible={selectedProduct != null || editData}
        onClose={() => {
          setSelectedProduct(null);
          setEditData(null);
        }}
        barcode={selectedProduct?.barcode}
        lotId={editData ? editData.lot_id : selectedProduct?.lotId}
        onAdd={addProduct}
        isSale={isSale}
        isTransfer={isTransfer}
        customerId={customerId}
        site={site}
        checkStock={checkStock}
        editData={editData}
        onSave={updateProduct}
      />
      <PTable>
        <table>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Product</th>
              <th>Lot</th>
              <th>Expiry Date</th>
              <th>Manufactured Date</th>
              {isSale && <th>Invoice Number</th>}
              {isTransfer && <th>Delivery Note</th>}
              <th>Quantity</th>
              {isSale && <th>Price</th>}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => {
              return (
                <tr key={product.key}>
                  <td>{product.supplier_name}</td>
                  <td>{product.product_name}</td>
                  <td>{product.lot_desc}</td>
                  <td>{product.exp_date}</td>
                  <td>{product.mfg_date}</td>
                  {(isSale || isTransfer) && <td>{product.delivery_note}</td>}
                  <td>{product.quantity}</td>
                  {isSale && <td>R {product.price}</td>}
                  <td>
                    <button
                      style={{
                        backgroundColor: '#02abde30',
                        color: '#02abde',
                        height: 35,
                        width: 35,
                        border: 'none',
                        borderRadius: 8,
                        marginRight: 10,
                        cursor: 'pointer'
                      }}
                      onClick={() => editProduct(product.key)}
                    >
                      <FiEdit />
                    </button>
                    <button
                      style={{
                        backgroundColor: '#d10e0e30',
                        color: '#d10e0e',
                        height: 35,
                        width: 35,
                        borderRadius: 8,
                        border: 'none',
                        cursor: 'pointer'
                      }}
                      onClick={() => removeProduct(product.key)}
                    >
                      <FiDelete />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
          disabled={!canAddProduct}
          className='add-product'
          onClick={() => {
            setShowModal(true);
          }}
          style={{ marginTop: 10 }}
        >
          <p>Click to Add Product</p>
        </button>
        <hr style={{ width: '100%', marginTop: 20 }} />
        <div>
          <Button
            title='Done'
            disabled={products.length == 0 || !canAddProduct || isLoading}
            onClick={() => {
              setIsLoading(true);
              onDone(products, successCallback);
            }}
          />
        </div>
      </PTable>
    </>
  );
};

export default ProductTable;
