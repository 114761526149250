import React, { useEffect, useState } from 'react';
import Dropdown from 'react-select';
import Select from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import './_styles.manage-expiring-stock.scss';
import Button from '../../../../components/Button/Button';
import {
  createExpiryConfig,
  getExpiryConfig,
  searchSites,
  updateExpiryConfig
} from '../../../../api';

const components = {
  DropdownIndicator: null
};

const createOption = (label) => ({
  label,
  value: label
});

const ManageExpiringStock = () => {
  const [data, setData] = useState({
    days: [],
    emails: [],
    limit: 0,
    id: null,
    sites: []
  });
  const [inputValue, setInputValue] = React.useState('');

  const getConfig = async () => {
    const response = await getExpiryConfig();
    setData(response.data);
  };

  useEffect(() => {
    getConfig();
  }, []);

  const saveConfig = async () => {
    const sendData = {
      ...data,
      sites: data.sites?.map((site) => site.value)
    };
    if (data.id) {
      await updateExpiryConfig(sendData);
    } else {
      await createExpiryConfig(sendData);
    }

    alert('Config saved successfully');
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setData({
          ...data,
          emails: [...data.emails, inputValue]
        });
        setInputValue('');
        event.preventDefault();
    }
  };

  const getDaysOfWeek = () => {
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    return daysOfWeek.map((day, index) => ({
      label: day,
      value: index + 1
    }));
  };

  const loadSites = async (inputValue) => {
    const response = await searchSites(inputValue);
    return response.data.map((site) => ({
      label: site.name,
      value: site.id
    }));
  };

  return (
    <div className='expiring-stock'>
      <h3>Manage Expiring Stock</h3>
      <div className='row'>
        <div>
          <label>Send Emails On</label>
          <Dropdown
            options={getDaysOfWeek()}
            isMulti
            placeholder='Send emails on'
            value={data.days.map((day) => ({
              label: getDaysOfWeek()[day - 1].label,
              value: day
            }))}
            onChange={(value) =>
              setData({ ...data, days: value.map((v) => v.value) })
            }
          />
        </div>
        <div>
          <label>Stock Expiring within {data.limit} days</label>
          <input
            type='number'
            placeholder='Stock Limit'
            value={data.limit}
            onChange={(e) => setData({ ...data, limit: e.target.value })}
          />
        </div>
      </div>
      <div>
        <label>Send Emails To</label>
        <Select
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onKeyDown={handleKeyDown}
          onInputChange={(newValue) => setInputValue(newValue)}
          placeholder='Type email and press enter'
          value={data.emails.map(createOption)}
          onChange={(value) =>
            setData({ ...data, emails: value.map((v) => v.value) })
          }
        />
      </div>
      <div>
        <label>Exclude Sites</label>
        <AsyncSelect
          isMulti
          placeholder='Exclude Sites'
          loadOptions={loadSites}
          value={data.sites}
          onChange={(value) => setData({ ...data, sites: value })}
        />
      </div>
      <Button title='Save' onClick={saveConfig} />
    </div>
  );
};

export default ManageExpiringStock;
