import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button/Button';
import InputField from '../../../components/InputField/InputField';
import { PTable, InputLabel } from './styles';
import { getExpiringReport } from '../../../api';
import ButtonSecondary from '../../../components/Button/ButtonSecondary';

const ExpiringScreen = () => {
  const [date, setDate] = useState();
  const [tableData, setTableData] = useState([]);

  const getReport = async (download) => {
    if (!date) {
      alert('Please select a date to report');
      return;
    }

    const { data } = await getExpiringReport(date, download);
    if (download) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Expiring Report ${date}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      setTableData(data.data);
      if (data.data.length == 0) {
        alert('No results');
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '10px 0',
          backgroundColor: '#f5f5f5',
          padding: '10px 0',
          borderRadius: 18,
        }}
      >
        <InputLabel>
          <label>Date</label>
          <InputField
            type='date'
            placeholder='Start Date'
            customStyle={{ minWidth: 250 }}
            onChange={(val) => setDate(val.target.value)}
          />
        </InputLabel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0 10px',
        }}
      >
        <Button title='Generate' onClick={() => getReport()} />
        <ButtonSecondary
          title='Download .csv'
          onClick={() => getReport(true)}
        />
      </div>

      <PTable>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Expiring by Date</th>
              <th>Product</th>
              <th>Product Description</th>
              <th>Lot</th>
              <th>Site Name</th>
              <th>Site Owner</th>
              <th>Expiry</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => {
              return (
                <tr>
                  <td>{row['Report Date']}</td>
                  <td>{row['Stock Expiring by Date']}</td>
                  <td>{row['Product']}</td>
                  <td>{row['Product Desc']}</td>
                  <td>{row['Lot']}</td>
                  <td>{row['Site Name']}</td>
                  <td>{row['Site Owner']}</td>
                  <td>{row['Expiry Date']}</td>
                  <td>{row['Quantity']}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PTable>
    </>
  );
};

export default ExpiringScreen;
