import React from 'react';
import ManageProductScreen from './Screens/ManageProduct/ManageProduct';
import ManagePartyScreen from './Screens/ManageParty/ManageParty';
import ManageSiteScreen from './Screens/ManageSite/ManageSite';
import ManageUserScreen from './Screens/ManageUser/ManageUser';
import ManageTemplate from './Screens/ManageTemplate/ManageTemplate';
import { useParams } from 'react-router-dom';

const ManageAdd = () => {
  const { activeTab } = useParams();

  switch (activeTab) {
    default:
    case 'products':
      return <ManageProductScreen />;
    case 'parties':
      return <ManagePartyScreen />;
    case 'sites':
      return <ManageSiteScreen />;
    case 'users':
      return <ManageUserScreen />;
    case 'templates':
      return <ManageTemplate />;
  }
};

export default ManageAdd;
