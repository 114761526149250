import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 20px;
  transition: 0.3s;
  border: none;
  margin: 20px auto;
  min-width: 400px;
  box-shadow: 0px 10px 20px #52577050;
  display: flex;
  flex-direction: column;
`;

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf2f5;
  padding-left: 125px;

  .groupInput {
    display: flex;

    > div {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .detailInput {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px 0;

    label {
      margin-left: 10px;
      margin-bottom: 5px;
    }

    input {
      margin: 0;
    }
  }
`;
