import React, { useState, useEffect } from 'react';
import Button from '../../../components/Button/Button';
import IndentedDropdown from '../../../components/IndentedDropdown/IndentedDropdown';
import { PTable, InputLabel } from './styles';
import {
  getParties,
  getSites,
  getSiteStockTake,
  getStockTakeReport,
} from '../../../api';
import ButtonSecondary from '../../../components/Button/ButtonSecondary';

const StockTakeScreen = () => {
  const [parties, setParties] = useState([]);
  const [party, setParty] = useState();
  const [date, setDate] = useState([]);
  const [dates, setDates] = useState();
  const [sites, setSites] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getParties(null, true, true).then(({ data }) => {
      const partiesToSelect = [];
      partiesToSelect.push(
        ...data.map((party) => {
          return {
            key: party.name,
            value: party.id,
          };
        })
      );
      setParties(partiesToSelect);
    });
  }, []);

  useEffect(() => {
    if (!party) {
      setSites([]);
      return;
    }
    getSites(party).then(({ data }) => {
      const sitesToSelect = [];
      sitesToSelect.push(
        ...data.map((site) => {
          return {
            key: site.name,
            value: site.id,
          };
        })
      );
      setSites(sitesToSelect);
    });
  }, [party]);

  useEffect(() => {
    const getStockTakes = async () => {
      const { data } = await getSiteStockTake(selectedSite);
      if (!data) {
        setDates([]);
      } else {
        setDates(
          data.map((d) => {
            return {
              key: d.date,
              value: d.date,
            };
          })
        );
      }
    };

    getStockTakes();
  }, [selectedSite]);

  const getReport = async (download) => {
    if (!selectedSite) {
      alert('Please select a site');
      return;
    }
    if (!date) {
      alert('Please select a date to report');
      return;
    }

    const { data } = await getStockTakeReport(selectedSite, date, download);
    if (download) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Stock Take Report ${date}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      setTableData(data.data);
      if (data.data.length == 0) {
        alert('No results');
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '10px 0',
          backgroundColor: '#f5f5f5',
          padding: '10px 0',
          borderRadius: 18,
        }}
      >
        <InputLabel>
          <label>Party</label>
          <IndentedDropdown
            placeholder='Party'
            icon='user'
            options={parties}
            onChange={(e) => setParty(e.target.value)}
            value={party}
          />
        </InputLabel>
        <InputLabel>
          <label>Sites</label>
          <IndentedDropdown
            placeholder='Site'
            icon='pin'
            options={sites}
            onChange={(e) => setSelectedSite(e.target.value)}
            value={selectedSite}
          />
        </InputLabel>
        <InputLabel>
          <label>Dates</label>
          <IndentedDropdown
            placeholder='Date'
            icon='calendar'
            options={dates}
            onChange={(e) => setDate(e.target.value)}
            value={date}
          />
        </InputLabel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '0 10px',
        }}
      >
        <Button title='Generate' onClick={() => getReport()} />
        <ButtonSecondary
          title='Download .csv'
          onClick={() => getReport(true)}
        />
      </div>

      <PTable>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Site Owner</th>
              <th>Site</th>
              <th>Product</th>
              <th>Product Description</th>
              <th>Lot</th>
              <th>Expiry Date</th>
              <th>System Quantity</th>
              <th>Actual Quantity</th>
              <th>Variance</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => {
              return (
                <tr>
                  <td>{row.Date}</td>
                  <td>{row['Site Owner']}</td>
                  <td>{row.Site}</td>
                  <td>{row.Product}</td>
                  <td>{row['Product Desc']}</td>
                  <td>{row.Lot}</td>
                  <td>{row['Expiry Date']}</td>
                  <td>{row['System Quantity']}</td>
                  <td>{row['Actual Quantity']}</td>
                  <td>{row.Variance}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </PTable>
    </>
  );
};

export default StockTakeScreen;
