import React, { useState, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import ButtonSecondary from '../../../../components/Button/ButtonSecondary';
import InputField from '../../../../components/InputField/InputField';
import SideBar from '../../../../components/SideBar/SideBar';
import qs from 'query-string';
import { Screen, Container } from './styles';
import { Link, useLocation } from 'react-router-dom';
import {
  getTransactingParties,
  getUser,
  updateUser,
  createUser
} from '../../../../api';
import IndentedDropdown from '../../../../components/IndentedDropdown/IndentedDropdown';

const initialValue = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  roles: []
};

const ManageUserScreen = () => {
  let e = useLocation();
  const params = qs.parse(e.search);
  const [results, setResults] = useState(initialValue);
  const [parties, setParties] = useState([]);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleRoleChange = (value, id) => {
    const index = results.roles
      .map((r) => {
        return r.id;
      })
      .indexOf(id);

    if (index > -1 && !value) {
      console.log('removing');
      let aux = [...results.roles];
      aux.splice(index, 1);
      console.log(aux);
      setResults({ ...results, roles: aux });
      return;
    }

    if (!value) {
      return;
    }

    if (index > -1) {
      let aux = [...results.roles];
      let item = { ...aux[index], value: value };
      aux[index] = item;
      setResults({ ...results, roles: aux });
    } else {
      setResults({ ...results, roles: [...results.roles, { id, value }] });
    }
  };

  const handleSubmit = async () => {
    if (params.id) {
      results.id = params.id;
      await updateUser(results);
    } else {
      await createUser(results);
    }
    window.location.href = '/manage/users';
  };

  useEffect(() => {
    const retrieveParties = async () => {
      const { data } = await getTransactingParties();
      setParties(
        data.map((supplier) => {
          return { name: supplier.nickname, value: supplier.id };
        })
      );
    };

    const getUserToEdit = async () => {
      const { data } = await getUser(params.id);
      console.log(data);
      setResults(data);
    };

    retrieveParties();

    if (params.id) {
      getUserToEdit();
    }
  }, []);

  return (
    <>
      <Screen>
        <Container>
          <h3>{params.id ? 'Edit' : 'Add'} User</h3>
          <div className='groupInput'>
            <div className='detailInput'>
              <label>First Name</label>
              <InputField
                name='firstName'
                placeholder='First Name'
                onChange={handleChange}
                value={results.firstName}
              />
            </div>
            <div className='detailInput'>
              <label>Last Name</label>
              <InputField
                name='lastName'
                placeholder='Last Name'
                onChange={handleChange}
                value={results.lastName}
              />
            </div>
          </div>
          <div className='detailInput'>
            <label>Username</label>
            <InputField
              name='email'
              placeholder='Username'
              onChange={handleChange}
              value={results.email}
            />
          </div>
          <div className='detailInput'>
            <label>Password</label>
            <InputField
              name='password'
              placeholder='Password'
              onChange={handleChange}
            />
          </div>
          <div className='detailInput'>
            <label>Mobile</label>
            <InputField
              name='phone'
              placeholder='Mobile'
              onChange={handleChange}
              value={results.phone}
            />
          </div>
          <div>
            {parties.map((party) => {
              if (!results || !results.roles) {
                return (
                  <div className='detailInput' key={party.name}>
                    <label>{party.name}</label>
                    <IndentedDropdown
                      style={{ maxWidth: '100%' }}
                      placeholder='Role'
                      options={[
                        { key: 'Administrator', value: 'administrator' },
                        { key: 'Transactor', value: 'transactor' },
                        { key: 'Reporting', value: 'reporting' }
                      ]}
                      onChange={(e) =>
                        handleRoleChange(e.target.value, party.value)
                      }
                    />
                  </div>
                );
              }
              const aux = results.roles.filter((r) => r.id == party.value);
              const val = aux.length > 0 ? aux[0].value : '';
              return (
                <div className='detailInput' key={party.name}>
                  <label>{party.name}</label>
                  <IndentedDropdown
                    style={{ maxWidth: '100%' }}
                    placeholder='Role'
                    options={[
                      { key: 'Administrator', value: 'administrator' },
                      { key: 'Transactor', value: 'transactor' },
                      { key: 'Reporting', value: 'reporting' }
                    ]}
                    value={val}
                    onChange={(e) =>
                      handleRoleChange(e.target.value, party.value)
                    }
                  />
                </div>
              );
            })}
          </div>
          <div>
            <Link to={`/manage/users`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button title={params.id ? 'Save' : 'Add'} onClick={handleSubmit} />
          </div>
        </Container>
      </Screen>
    </>
  );
};

export default ManageUserScreen;
