import React, { useState, useEffect } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import Dropdown from '../../components/Dropdown/Dropdown';
import { Screen } from './styles';
import ProductTable from '../../components/ProductTable/ProductTable';
import { getSites, createStock, addLot, getParties } from '../../api';
import '../../App.css';

const StockTakeScreen = () => {
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState(0);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(0);

  useEffect(() => {
    getParties(null, true, true).then(({ data }) => {
      setParties(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  }, []);

  const selectParty = (e) => {
    setSelectedParty(e.target.value);
    getSites(e.target.value).then(({ data }) => {
      setSites(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  };

  const selectSite = (e) => {
    setSelectedSite(e.target.value);
  };

  const takeStock = async (products, callback) => {
    var quantity = 0;

    var productsToSend = [];

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const lot = await addLot({
        product_id: product.product_id,
        lot_desc: product.lot_desc,
        barcode: product.barcode,
        mfg_date: product.mfg_date,
        exp_date: product.exp_date
      });
      products[i].lot = lot.data.id;
      quantity += parseInt(product.quantity);

      productsToSend.push({
        id: product.product_id,
        lot: lot.data.id,
        quantity: product.quantity
      });
    }

    const requestData = {
      user_id: JSON.parse(localStorage.getItem('profile')).userId,
      site_id: selectedSite,
      quantity: quantity,
      products: productsToSend
    };

    const { data } = await createStock(requestData);

    if (data) {
      alert(data.message);
      callback(data.success);
    } else {
      callback(false);
    }
  };

  return (
    <>
      <Screen>
        <div className='dropdowns' style={{ width: '90%' }}>
          <Dropdown
            placeholder='Select a Party'
            icon='user'
            options={parties}
            onChange={selectParty}
          />
          {selectedParty != 0 && (
            <Dropdown
              placeholder='Select a site'
              icon='pin'
              options={sites}
              onChange={selectSite}
            />
          )}
        </div>
        <ProductTable canAddProduct={selectedSite != 0} onDone={takeStock} />
      </Screen>
    </>
  );
};

export default StockTakeScreen;
