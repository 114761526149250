import React, { useState, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import ButtonSecondary from '../../../../components/Button/ButtonSecondary';
import InputField from '../../../../components/InputField/InputField';
import SideBar from '../../../../components/SideBar/SideBar';
import qs from 'query-string';
import { Screen, Container } from './styles';
import { Link, useSearchParams } from 'react-router-dom';
import IndentedDropdown from '../../../../components/IndentedDropdown/IndentedDropdown';
import {
  addParty,
  editParty,
  getParty,
  getTemplates as getTemplatesCall
} from '../../../../api';

const initialValue = {
  id: '',
  name: '',
  account_code: '',
  nickname: '',
  address_1: '',
  address_2: '',
  city: '',
  province: '',
  postal_code: '',
  country_code: '',
  vat_no: '',
  reg_no: '',
  price_template_id: '',
  customer_flag: false,
  transacting_party: false
};

const ManagePartyScreen = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [results, setResults] = useState(initialValue);
  const [templates, setTemplates] = useState([]);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleCheckBox = (e) => {
    setResults({ ...results, [e.target.name]: e.target.checked });
  };

  const handleTemplateChange = (e) => {
    setResults({ ...results, price_template_id: e.target.value });
  };

  const handleSubmit = async () => {
    if (!results.name) {
      alert('Please supply party with a name');
      return;
    }
    if (!results.nickname) {
      alert('Please supply party with a nickname');
      return;
    }

    if (id) {
      await editParty(id, results);
    } else {
      await addParty(results);
    }
    window.location.href = '/manage/parties';
  };

  useEffect(() => {
    const getTemplates = async () => {
      const { data } = await getTemplatesCall();
      console.log(data);
      setTemplates(
        data.map((temp) => {
          return { key: temp.name, value: temp.id };
        })
      );
    };

    const getPartyToEdit = async () => {
      const { data } = await getParty(id);
      setResults(data);
    };

    getTemplates();
    if (id) {
      getPartyToEdit();
    }
  }, []);

  return (
    <>
      <Screen>
        <Container>
          <h3>{id ? 'Edit' : 'Add'} Party</h3>
          <div className='checkBox'>
            <input
              name='customer_flag'
              onChange={handleCheckBox}
              type='checkbox'
              checked={results.customer_flag}
            />
            <label>Customer</label>
          </div>
          <div className='checkBox'>
            <input
              name='transacting_party'
              onChange={handleCheckBox}
              type='checkbox'
              checked={results.transacting_party}
            />
            <label>Transacting Party</label>
          </div>
          <div className='detailInput'>
            <label>Name</label>
            <InputField
              name='name'
              placeholder='Name'
              onChange={handleChange}
              value={results.name}
            />
          </div>
          <div className='detailInput'>
            <label>Account Code</label>
            <InputField
              name='account_code'
              placeholder='Account Code'
              onChange={handleChange}
              value={results.account_code}
            />
          </div>
          <div className='detailInput'>
            <label>Nickname</label>
            <InputField
              name='nickname'
              placeholder='Nickname'
              onChange={handleChange}
              value={results.nickname}
            />
          </div>
          <div className='groupInput'>
            <div className='detailInput'>
              <label>Address 1</label>
              <InputField
                name='address_1'
                placeholder='Address 1'
                onChange={handleChange}
                value={results.address_1}
              />
            </div>
            <div className='detailInput'>
              <label>Address 2</label>
              <InputField
                name='address_2'
                placeholder='Address 2'
                onChange={handleChange}
                value={results.address_2}
              />
            </div>
          </div>
          <div className='detailInput'>
            <label>Address 2</label>
            <InputField
              name='address_3'
              placeholder='Address 3'
              onChange={handleChange}
              value={results.address_3}
            />
          </div>
          <div className='groupInput'>
            <div className='detailInput'>
              <label>City</label>
              <InputField
                name='city'
                placeholder='City'
                onChange={handleChange}
                value={results.city}
              />
            </div>
            <div className='detailInput'>
              <label>Province</label>
              <InputField
                name='province'
                placeholder='Province'
                onChange={handleChange}
                value={results.province}
              />
            </div>
          </div>
          <div className='groupInput'>
            <div className='detailInput'>
              <label>Postal Code</label>
              <InputField
                name='post_code'
                placeholder='Postal Code'
                onChange={handleChange}
                value={results.post_code}
              />
            </div>
            <div className='detailInput'>
              <label>Country Code</label>
              <InputField
                name='country_code'
                placeholder='Country Code'
                onChange={handleChange}
                value={results.country_code}
              />
            </div>
          </div>
          <div className='groupInput'>
            <div className='detailInput'>
              <label>VAT Number</label>
              <InputField
                name='vat_no'
                placeholder='VAT Number'
                onChange={handleChange}
                value={results.vat_no}
              />
            </div>
            <div className='detailInput'>
              <label>Registration Number</label>
              <InputField
                name='reg_no'
                placeholder='Registration Number'
                onChange={handleChange}
                value={results.reg_no}
              />
            </div>
          </div>
          <div className='detailInput'>
            <label>Price Template</label>
            <IndentedDropdown
              options={templates}
              placeholder='Price Template'
              style={{ maxWidth: '100%' }}
              value={results.price_template_id}
              onChange={handleTemplateChange}
            />
          </div>
          <div className='buttons'>
            <Link to={`/manage/parties`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button onClick={handleSubmit} title={id ? 'Save' : 'Add'} />
          </div>
        </Container>
      </Screen>
    </>
  );
};

export default ManagePartyScreen;
