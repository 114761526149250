import styled from 'styled-components';

export const StyledDropdown = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 12px;
  text-align: center;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #d7e3ec;

  margin-right: 20px;

  select {
    border: none;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
  }
`;
