import React from 'react';
import './_styles.button.scss';

const Button = ({ title, type, disabled, onClick }) => {
  return (
    <button
      className='button'
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Button;
