import React, { useState, useEffect } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import { Screen } from './styles';
import Dropdown from '../../components/Dropdown/Dropdown';
import ProductTable from '../../components/ProductTable/ProductTable';
import { getSites, getParties, createIssue, addLot } from '../../api';

const TransfersScreen = () => {
  const [sites, setSites] = useState([]);
  const [fromPartySites, setFromPartySites] = useState([]);
  const [toPartySites, setToPartySites] = useState([]);
  const [parties, setParties] = useState([]);
  const [selectedSiteFrom, setSelectedSiteFrom] = useState(0);
  const [selectedSiteTo, setSelectedSiteTo] = useState(0);
  const [selectedFromParty, setSelectedFromParty] = useState(0);
  const [selectedToParty, setSelectedToParty] = useState(0);

  useEffect(() => {
    getSites(JSON.parse(localStorage.getItem('profile')).partyId).then(
      ({ data }) => {
        console.log(data);
        setSites(
          data.map((site) => {
            return {
              key: site.name,
              value: site.id
            };
          })
        );
      }
    );

    getParties().then(({ data }) => {
      console.log(data);
      setParties(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  }, []);

  const selectSiteFrom = (e) => {
    console.log(e.target.value);
    setSelectedSiteFrom(e.target.value);
  };

  const selectSiteTo = (e) => {
    setSelectedSiteTo(e.target.value);
  };

  const selectFromParty = (e) => {
    setSelectedFromParty(e.target.value);
    getSites(e.target.value).then(({ data }) => {
      console.log(data);
      setFromPartySites(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  };

  const selectToParty = (e) => {
    setSelectedToParty(e.target.value);
    getSites(e.target.value).then(({ data }) => {
      console.log(data);
      setToPartySites(
        data.map((site) => {
          return {
            key: site.name,
            value: site.id
          };
        })
      );
    });
  };

  const canAddProduct = () => {
    return selectedSiteFrom != 0 && selectedSiteTo != 0;
  };

  const onSubmit = async (products, callback) => {
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const lot = await addLot({
        product_id: product.product_id,
        lot_desc: product.lot_desc,
        barcode: product.barcode,
        mfg_date: product.mfg_date,
        exp_date: product.exp_date
      });

      products[i].lotId = lot.data.id;
    }

    var quantity = 0;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      quantity += parseInt(product.quantity);
    }

    const requestData = {
      user_id: JSON.parse(localStorage.getItem('profile')).userId,
      from_party_id: selectedFromParty,
      from_site_id: selectedSiteFrom,
      to_site_id: selectedSiteTo,
      to_party_id: selectedToParty,
      quantity: quantity,
      products: products.map((res) => {
        return {
          id: res.product_id,
          lot: res.lotId,
          quantity: res.quantity,
          delivery_note: res.delivery_note
        };
      })
    };

    const { data } = await createIssue(requestData);

    if (data) {
      alert(data.message);
      callback(data.success);
    } else {
      callback(false);
    }
  };

  return (
    <>
      <Screen>
        <div className='dropdowns' style={{ width: '90%' }}>
          <Dropdown
            placeholder='From Party'
            icon='user'
            options={parties}
            onChange={selectFromParty}
          />
          {selectedFromParty != 0 && (
            <Dropdown
              placeholder='Site From'
              icon='pin'
              options={fromPartySites}
              onChange={selectSiteFrom}
            />
          )}
          <Dropdown
            placeholder='To Party'
            icon='user'
            options={parties}
            onChange={selectToParty}
          />
          {selectedToParty != 0 && (
            <Dropdown
              placeholder='To Site'
              icon='pin'
              options={toPartySites}
              onChange={selectSiteTo}
            />
          )}
        </div>
        <ProductTable
          canAddProduct={canAddProduct()}
          onDone={onSubmit}
          site={selectedSiteFrom}
          checkStock={true}
          isTransfer={true}
        />
      </Screen>
    </>
  );
};

export default TransfersScreen;
