import React, { useEffect } from 'react';
import './index.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './screens/Home/Home';
import Manage from './screens/Manage/Manage';
import Receipts from './screens/Receipts/Receipts';
import Reports from './screens/Reports/Reports';
import Sell from './screens/Sell/Sell';
import StockTake from './screens/StockTake/StockTake';
import Transfers from './screens/Transfers/Transfers';
import Login from './screens/Login/Login';
import Privacy from './screens/Privacy/Privacy';
import { getRole } from './api';
import ManageAdd from './screens/Manage/ManageAdd';
import NavigationFrame from './components/NavigationFrame/NavigationFrame';

const ProtectedRoute = ({ children }) => {
  const userData = JSON.parse(localStorage.getItem('profile'));

  if (!userData) {
    return <Navigate to={'/login'} replace />;
  }

  return <NavigationFrame>{children}</NavigationFrame>;
};

function App() {
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('profile'));

    if (userData) {
      getRole(userData.token, userData.partyId)
        .then(({ data }) => {
          userData.role = data.role;
          localStorage.setItem('profile', JSON.stringify(userData));
        })
        .catch((err) => {
          localStorage.clear();
          window.location.reload();
        });
    }
  }, [window.location.href]);

  return (
    <BrowserRouter>
      <div className='App'>
        <Routes>
          <Route path='/privacy' exact element={<Privacy />} />
          <Route path='/login' exact element={<Login />} />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path='/manage'
            element={
              <ProtectedRoute>
                <Manage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/manage/:activeTab'
            element={
              <ProtectedRoute>
                <Manage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/manage/:activeTab/add'
            element={
              <ProtectedRoute>
                <ManageAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path='/manage/:activeTab/edit'
            element={
              <ProtectedRoute>
                <ManageAdd />
              </ProtectedRoute>
            }
          />
          <Route
            path='/receipts'
            element={
              <ProtectedRoute>
                <Receipts />
              </ProtectedRoute>
            }
          />
          <Route
            path='/transfers'
            element={
              <ProtectedRoute>
                <Transfers />
              </ProtectedRoute>
            }
          />
          <Route
            path='/sales'
            element={
              <ProtectedRoute>
                <Sell />
              </ProtectedRoute>
            }
          />
          <Route
            path='/stocktake'
            element={
              <ProtectedRoute>
                <StockTake />
              </ProtectedRoute>
            }
          />
          <Route
            path='/reports'
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path='/reports/:reportType'
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
