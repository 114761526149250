import React from 'react';
import './_styles.input-field.scss';

const InputField = ({
  placeholder,
  onChange,
  name,
  type,
  id,
  value,
  disabled,
  customStyle,
  onSubmit
}) => {
  return (
    <input
      className='input-field'
      style={customStyle}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      type={type}
      id={id}
      value={value}
      disabled={disabled}
      min='1997-01-01'
      max='2030-12-31'
    />
  );
};

export default InputField;
