import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const DetailModal = styled.div`
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  animation-name: ${fadeIn};
  animation-duration: 0.3s;
  margin-bottom: 200px;
`;

const popUp = keyframes`
    from { opacity: 0; transform: translateY(100px) }
    to { opacity: 1;  transform: translateY(0px) }
`;

export const DetailModalContent = styled.div`
  text-align: center;
  background-color: #fefefe;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  animation-name: ${popUp};
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  animation-fill-mode: both;

  .searchResult {
    background-color: #e0e0e0;
    margin: 5px 0;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    text-align: left;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    P {
      margin: 0;
      margin-left: 10px;

      &:first-child {
        color: #02abde;
        font-size: 15px;
      }
    }

    &:hover {
      background-color: #efefef;
    }
  }
`;
