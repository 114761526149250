import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf2f5;

  > div:last-child {
    display: flex;
    flex-direction: row;
  }
`;
