import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  > div {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    border-radius: 14px;
    box-shadow: 0px 10px 20px #52577050;

    > form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
