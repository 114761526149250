import React, { useEffect } from 'react';
import {
  FiHome,
  FiBriefcase,
  FiFileText,
  FiBox,
  FiInbox,
  FiShoppingBag,
  FiTruck,
  FiLogOut
} from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import './_styles.side-bar.scss';

const SideBar = () => {
  const userData = JSON.parse(localStorage.getItem('profile'));
  return (
    <div className='side-bar'>
      <ReactTooltip
        place='right'
        backgroundColor='#fff'
        effect='solid'
        textColor='#000'
        border
        opacity='1'
      />
      <img
        style={{ maxWidth: 50, marginTop: 10 }}
        src={userData.partyId == 1 ? '/favicon.png' : '/images/im.png'}
      />
      <Link
        data-tip='Dashboard'
        className={window.location.pathname === '/' ? 'active' : ''}
        to='/'
      >
        <FiHome size={20} />
      </Link>
      {userData.role != 'reporting' && (
        <>
          <Link
            data-tip='Receipts'
            className={
              window.location.pathname.includes('receipt') ? 'active' : ''
            }
            to='/receipts'
          >
            <FiBox size={20} />
          </Link>
          <Link
            data-tip='Transfers'
            className={
              window.location.pathname.includes('transfer') ? 'active' : ''
            }
            to='/transfers'
          >
            <FiTruck size={20} />
          </Link>
          <Link
            data-tip='Sales'
            className={
              window.location.pathname.includes('sales') ? 'active' : ''
            }
            to='/sales'
          >
            <FiShoppingBag size={20} />
          </Link>
          <Link
            data-tip='Stock Take'
            className={
              window.location.pathname.includes('stock') ? 'active' : ''
            }
            to='/stocktake'
          >
            <FiInbox size={20} />
          </Link>
        </>
      )}
      <Link
        data-tip='Reports'
        className={window.location.pathname.includes('report') ? 'active' : ''}
        to='/reports'
      >
        <FiFileText size={20} />
      </Link>
      {userData.role != 'reporting' && (
        <Link
          data-tip='Manage Business'
          className={
            window.location.pathname.includes('manage') ? 'active' : ''
          }
          to='/manage'
        >
          <FiBriefcase size={20} />
        </Link>
      )}
      <a
        data-tip='Logout'
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        <FiLogOut size={20} color='#d10e0e' />
      </a>
    </div>
  );
};

export default SideBar;
