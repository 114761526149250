import React, { useState, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import ButtonSecondary from '../../../../components/Button/ButtonSecondary';
import InputField from '../../../../components/InputField/InputField';
import SideBar from '../../../../components/SideBar/SideBar';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import {
  addSite,
  editSite,
  getMinStock,
  getOwners,
  getParties,
  getSite,
  searchProducts,
  setSiteMinStock
} from '../../../../api';
import IndentedDropdown from '../../../../components/IndentedDropdown/IndentedDropdown';
import './_styles.manage-site.scss';
import AsyncSelect from 'react-select/async';
import { FaTimes } from 'react-icons/fa';

const initialValue = {
  owner_id: 0,
  name: '',
  address_1: '',
  address_2: '',
  city: '',
  province: '',
  post_code: '',
  country_code: '',
  latitude: '',
  longitude: ''
};

const ManageSiteScreen = () => {
  let e = useLocation();
  const params = qs.parse(e.search);
  const [results, setResults] = useState(initialValue);
  const [activeTab, setActiveTab] = useState('general');
  const [parties, setParties] = useState([]);
  const [minStocks, setMinStocks] = useState([]);
  const [curMinStocks, setCurMinStocks] = useState([]);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleOwnerChange = (e) => {
    setResults({ ...results, owner_id: e.target.value });
  };

  const handleSubmit = async () => {
    let sendData = results;

    if (sendData.longitude == '') {
      sendData.longitude = 0;
    }

    if (sendData.latitude == '') {
      sendData.latitude = 0;
    }

    if (params.id) {
      await editSite(params.id, sendData);
    } else {
      await addSite(sendData);
    }
    window.location.href = '/manage/sites';
  };

  useEffect(() => {
    const retrieveParties = async () => {
      const { data } = await getOwners();
      setParties(
        data.map((supplier) => {
          return { key: supplier.nickname, value: supplier.id };
        })
      );
    };

    const getSiteToEdit = async () => {
      const { data } = await getSite(params.id);
      setResults(data);
    };

    const getSiteMinStock = async () => {
      const { data } = await getMinStock(params.id);

      setMinStocks(data);
      setCurMinStocks(data);
    };

    retrieveParties();

    if (params.id) {
      getSiteToEdit();
      getSiteMinStock();
    }
  }, []);

  const loadProducts = async (inputValue) => {
    const response = await searchProducts(inputValue);

    return response.data.map((product) => ({
      label: product.short_name,
      value: product
    }));
  };

  const handleMinStockChange = (e) => {
    if (minStocks.some((stock) => stock.id == e.value.id)) {
      alert('Product already added');
      return;
    }

    setMinStocks([{ ...e.value, minimum: '' }, ...minStocks]);
  };

  const handleSaveMinStock = async () => {
    const response = await setSiteMinStock(
      params.id,
      minStocks.map((stock) => ({
        productId: stock.id,
        minimum: parseInt(stock.minimum) || 0
      }))
    );

    if (response.status == 200) {
      alert('Minimum Stock Saved');
      setCurMinStocks([...minStocks]);
    } else {
      alert('Error saving minimum stock');
    }
  };

  console.log(minStocks, curMinStocks);

  return (
    <div className='manage-site'>
      <div className='tabs-header'>
        <div
          className={`tab ${
            activeTab == 'general' || !activeTab ? 'active' : null
          }`}
          onClick={() => setActiveTab('general')}
        >
          General
        </div>
        {params.id && (
          <div
            className={`tab ${activeTab == 'alerts' ? 'active' : null}`}
            onClick={() => setActiveTab('alerts')}
          >
            Minimum Stock
          </div>
        )}
      </div>
      {activeTab == 'general' && (
        <>
          <div className='content'>
            <div className='detailInput'>
              <label>Owner</label>
              <IndentedDropdown
                options={parties}
                placeholder='Owner'
                style={{ maxWidth: '100%' }}
                value={results.owner_id}
                onChange={handleOwnerChange}
              />
            </div>
            <div className='detailInput'>
              <label>Name</label>
              <InputField
                name='name'
                placeholder='Name'
                onChange={handleChange}
                value={results.name}
              />
            </div>
            <div className='row'>
              <div className='detailInput'>
                <label>Address 1</label>
                <InputField
                  name='address_1'
                  placeholder='Address 1'
                  onChange={handleChange}
                  value={results.address_1}
                />
              </div>
              <div className='detailInput'>
                <label>Address 2</label>
                <InputField
                  name='address_2'
                  placeholder='Address 2'
                  onChange={handleChange}
                  value={results.address_2}
                />
              </div>
            </div>
            <div className='row'>
              <div className='detailInput'>
                <label>City</label>
                <InputField
                  name='city'
                  placeholder='City'
                  onChange={handleChange}
                  value={results.city}
                />
              </div>
              <div className='detailInput'>
                <label>Province</label>
                <InputField
                  name='province'
                  placeholder='Province'
                  onChange={handleChange}
                  value={results.province}
                />
              </div>
            </div>
            <div className='row'>
              <div className='detailInput'>
                <label>Postal Code</label>
                <InputField
                  name='post_code'
                  placeholder='Postal Code'
                  onChange={handleChange}
                  value={results.post_code}
                />
              </div>
              <div className='detailInput'>
                <label>Country Code</label>
                <InputField
                  name='country_code'
                  placeholder='Country Code'
                  onChange={handleChange}
                  value={results.country_code}
                />
              </div>
            </div>
            <div className='row'>
              <div className='detailInput'>
                <label>Latitude</label>
                <InputField
                  name='latitude'
                  placeholder='Latitude'
                  onChange={handleChange}
                  value={results.latitude}
                />
              </div>
              <div className='detailInput'>
                <label>Longitude</label>
                <InputField
                  name='longitude'
                  placeholder='Longitude'
                  onChange={handleChange}
                  value={results.longitude}
                />
              </div>
            </div>
          </div>
          <div className='buttons'>
            <Link to={`/manage/sites`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button title={params.id ? 'Save' : 'Add'} onClick={handleSubmit} />
          </div>
        </>
      )}
      {activeTab == 'alerts' && (
        <>
          <div className='products-list'>
            <div className='product-header'>
              <p>
                <span>Site:</span> {results.name}
              </p>
              <AsyncSelect
                placeholder='Add Product'
                loadOptions={loadProducts}
                onChange={handleMinStockChange}
                value={null}
              />
            </div>
            {minStocks.map((stock) => (
              <div className='product'>
                <div>
                  <p>{stock.short_name}</p>
                  <span>{stock.long_name}</span>
                  {(!curMinStocks.some((item) => item.id == stock.id) ||
                    curMinStocks.find((item) => item.id == stock.id)?.minimum !=
                      stock.minimum) && <p>Not Saved</p>}
                </div>
                <input
                  placeholder='Minimum Stock'
                  type='number'
                  value={stock.minimum}
                  onChange={(e) => {
                    setMinStocks(
                      minStocks.map((item) =>
                        item.id == stock.id
                          ? { ...item, minimum: e.target.value }
                          : item
                      )
                    );
                  }}
                />
                <button
                  onClick={() =>
                    setMinStocks(
                      minStocks.filter((item) => item.id !== stock.id)
                    )
                  }
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
          <div className='buttons'>
            <Link to={`/manage/sites`}>
              <ButtonSecondary title='Back' />
            </Link>
            <Button
              title={params.id ? 'Save' : 'Add'}
              onClick={handleSaveMinStock}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ManageSiteScreen;
