import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 20px;
  transition: 0.3s;
  border: none;
  margin: 20px auto;
  max-width: 400px;
  box-shadow: 0px 10px 20px #52577050;
  display: flex;
  flex-direction: column;
`;

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf2f5;
  padding-left: 125px;

  > div:last-child {
    display: flex;
    flex-direction: row;
  }
`;

export const DashboardHeader = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const TopTile = styled.div`
  background-color: white;
  min-width: 20%;
  border-radius: 18px;
  box-shadow: 0 10px 15px #205c8030;

  p:last-child {
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const DashboardBody = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 30px 0;

  > div:first-child {
    background-color: white;
    min-width: 20%;
    border-radius: 18px;
    box-shadow: 0 10px 15px #205c8030;
    flex: 3;
    margin-right: 20px;
  }

  > div:last-child {
    flex: 1;
    display: flex;
    margin-left: 20px;
    flex-direction: column;

    > div {
      background-color: white;
      min-width: 20%;
      border-radius: 18px;
      box-shadow: 0 10px 15px #205c8030;

      &:first-child {
        flex: 1;
        margin-bottom: 10px;
      }

      &:last-child {
        flex: 2;
        margin-top: 10px;
      }
    }
  }
`;
