import React, { useState, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import ButtonSecondary from '../../../../components/Button/ButtonSecondary';
import InputField from '../../../../components/InputField/InputField';
import IndentedDropdown from '../../../../components/IndentedDropdown/IndentedDropdown';
import qs from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import {
  getParties,
  getProductById,
  addProduct,
  editProduct,
  searchSites
} from '../../../../api';
import './_styles.manage-product.scss';
import AsyncSelect from 'react-select/async';

const initialValue = {
  id: '',
  long_name: '',
  short_name: '',
  barcode: '',
  nappi_code: '',
  supplier: ''
};

const ManageProductScreen = () => {
  let e = useLocation();
  const params = qs.parse(e.search);
  const [results, setResults] = useState(initialValue);
  const [activeTab, setActiveTab] = useState('general');
  const [suppliers, setSuppliers] = useState([]);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleSupplierChange = (e) => {
    setResults({ ...results, supplier: e.target.value });
  };

  const handleSubmit = async () => {
    if (!results.long_name) {
      alert('Please supply product with a long name');
      return;
    }

    if (!results.short_name) {
      alert('Please supply product with a short name');
      return;
    }

    if (!results.barcode) {
      alert('Please supply product with a barcode');
      return;
    }

    if (!results.supplier || results.supplier < 1) {
      alert('Please supply product with a supplier');
      return;
    }

    if (params.id) {
      await editProduct(params.id, results);
    } else {
      await addProduct(results);
    }
    window.location.href = '/manage/products';
  };

  useEffect(() => {
    const retrieveSuppliers = async () => {
      const { data } = await getParties(true);
      setSuppliers(
        data.map((supplier) => {
          return { key: supplier.name, value: supplier.id };
        })
      );
    };

    const getProduct = async () => {
      const { data } = await getProductById(params.id);
      setResults({
        long_name: data.long_name,
        short_name: data.short_name,
        barcode: data.barcode,
        nappi_code: data.nappi_code,
        supplier: data.PartyId
      });
    };

    retrieveSuppliers();

    if (params.id) {
      getProduct();
    }
  }, []);

  const loadSites = async (inputValue) => {
    const response = await searchSites(inputValue);
    return response.data.map((site) => ({
      label: site.name,
      value: site.id
    }));
  };

  return (
    <div className='manage-product'>
      {/* <div className='tabs-header'>
        <div
          className={`tab ${
            activeTab == 'general' || !activeTab ? 'active' : null
          }`}
          onClick={() => setActiveTab('general')}
        >
          General
        </div>
        <div
          className={`tab ${activeTab == 'alerts' ? 'active' : null}`}
          onClick={() => setActiveTab('alerts')}
        >
          Minimum Stock Alerts
        </div>
      </div> */}
      {activeTab == 'general' && (
        <>
          <div className='content'>
            <div className='row'>
              <div className='detail-input'>
                <label>Long Name</label>
                <InputField
                  name='long_name'
                  placeholder='Long Name'
                  onChange={handleChange}
                  value={results.long_name}
                />
              </div>
              <div className='detail-input'>
                <label>Short Name</label>
                <InputField
                  name='short_name'
                  placeholder='Short Name'
                  onChange={handleChange}
                  value={results.short_name}
                />
              </div>
            </div>
            <div className='detail-input'>
              <label>Barcode</label>
              <InputField
                name='barcode'
                placeholder='Barcode'
                onChange={handleChange}
                value={results.barcode}
              />
            </div>
            <div className='detail-input'>
              <label>NAPPI Code</label>
              <InputField
                name='nappi_code'
                placeholder='NAPPI Code'
                onChange={handleChange}
                value={results.nappi_code}
              />
            </div>
            <div className='detail-input'>
              <label>Supplier</label>
              <IndentedDropdown
                options={suppliers}
                placeholder='Supplier'
                style={{ maxWidth: '100%' }}
                value={results.supplier}
                onChange={handleSupplierChange}
              />
            </div>
          </div>
          <div className='buttons'>
            <Link to={`/manage/products`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button title={params.id ? 'Save' : 'Add'} onClick={handleSubmit} />
          </div>
        </>
      )}

      {activeTab == 'alerts' && (
        <>
          <div className='content'>
            <div className='detail-input'>
              <label>Minimum Stock Level</label>
              <InputField
                name='min_stock_level'
                placeholder='Minimum Stock Level'
                onChange={handleChange}
                value={results.min_stock_level}
                type='number'
              />
            </div>
            <div className='detail-input'>
              <label>Sites</label>
              <AsyncSelect
                isMulti
                placeholder='Sites'
                loadOptions={loadSites}
              />
            </div>
          </div>
          <div className='buttons'>
            <Link to={`/manage/products`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button title='Save' onClick={handleSubmit} />
          </div>
        </>
      )}
    </div>
  );
};

export default ManageProductScreen;
