import axios from 'axios';

const API = axios.create({
  baseURL: 'https://stock.compassmedical.co.za/api/'
  // baseURL: 'https://demo.adriaanbotha.com/api/'
  // baseURL: 'https://compass.solis-studios.com/api/',
  // baseURL: 'http://localhost:4000/api/'
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers['token'] = JSON.parse(localStorage.getItem('profile')).token;
    req.headers['device'] = 'web';
  }

  return req;
});

export const login = (formData) => API.post('user/login', formData);

export const getSites = (owner) =>
  API.get('site', { params: { owner: owner } });

export const getSite = (id) => API.get('site/' + id);
export const getSiteStockTake = (site) =>
  API.get('site/stocktakes', { params: { site } });
export const addSite = (data) => API.post('site/', data);
export const editSite = (id, data) => API.put('site/' + id, data);
export const searchSites = (name) => API.get('site/search/' + name);

export const getProducts = () => API.post('product/list');
export const searchProducts = (name) => API.get('product/search/' + name);
export const getProductById = (id) => API.get('product/' + id);
export const productStockCheck = (site, product, lot) =>
  API.get('product/stockcheck', { params: { site, product, lot } });
export const editProduct = (id, data) => API.put('product/' + id, data);

export const getProduct = (barcode, customer, lotId) =>
  API.get('product', { params: { barcode, customer, lotId } });
export const getProductCode = (barcode) =>
  API.post('product/code', { barcode: barcode });
export const getProductByName = (name, site) =>
  API.post('product/namesearch', { name: name, site: site });
export const getProductLocations = (id, lot_id) =>
  API.post('product/location', { id: id, lot_id: lot_id });
export const addProduct = (data) => API.post('product/add', data);

export const addLot = (data) => API.post('lot/add', data);

export const manageData = (item, id) =>
  API.get(`manage/${item}`, { params: { editId: id } });
export const manageAddData = (item, data) =>
  API.post(`manage/add/${item}`, data);
export const manageEditData = (item, data) =>
  API.post(`manage/edit/${item}`, data);
export const manageDeleteData = (item, data) =>
  API.post(`manage/delete/${item}`, data);

export const getParties = (supplier, customer, own) =>
  API.get('party', { params: { supplier, customer, own } });
export const getOwners = () => API.get('party/owners');
export const getParty = (id) => API.get('party/' + id);
export const editParty = (id, data) => API.put('party/' + id, data);
export const addParty = (data) => API.post('party', data);

export const getTransactingParties = () => API.get('party/transactors');

export const getTemplates = () => API.get('templates');
export const getTemplate = (id) => API.get('templates/' + id);
export const createTemplate = (data) => API.post('templates/', data);
export const editTemplate = (id, data) => API.put('templates/' + id, data);
export const getUsers = () => API.get('user');
export const getUser = (id) => API.get('user/' + id);

export const createReceipt = (data) => API.post('receipts/create', data);
export const createIssue = (data) => API.post('issues/create', data);
export const createSale = (data) => API.post('sales/create', data);
export const createStock = (data) => API.post('stocktake/create', data);
export const createUser = (data) => API.post('user/create', data);
export const updateUser = (data) => API.put('user/update', data);
export const getRole = (token, party) =>
  API.get('user/role', { params: { party }, headers: { token } });
export const getData = () => API.get('data');
export const getManagementData = (table) => API.get('webmanagement/' + table);

export const getSalesReport = (
  startDate,
  endDate,
  product,
  user,
  client,
  csv
) =>
  API.get('reports/sales', {
    params: { startDate, endDate, product, user, client, csv },
    responseType: csv ? 'blob' : 'json'
  });

export const getExpiringReport = (date, csv) =>
  API.get('reports/expiring', {
    params: { date, csv },
    responseType: csv ? 'blob' : 'json'
  });

export const getStockOnHandReport = (party, site, product, date, csv) =>
  API.get('reports/stockonhand', {
    params: { party, site, product, date, csv },
    responseType: csv ? 'blob' : 'json'
  });

export const getStockTakeReport = (site, date, csv) =>
  API.get('reports/stocktake', {
    params: { site, date, csv },
    responseType: csv ? 'blob' : 'json'
  });

export const getRecallReport = (search, csv) =>
  API.get('reports/recall', {
    params: { search, csv },
    responseType: csv ? 'blob' : 'json'
  });

export const getExpiryConfig = () => API.get('stock/expiring');
export const updateExpiryConfig = (data) =>
  API.put(`stock/expiring/${data.id}`, data);
export const createExpiryConfig = (data) => API.post('stock/expiring', data);

export const setSiteMinStock = (siteId, data) =>
  API.post('stock/minimum/site/' + siteId, data);
export const getMinStock = (siteId) => API.get('stock/minimum/site/' + siteId);
