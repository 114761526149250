import React, { useState, useEffect } from 'react';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { Screen } from './styles';
import { login, getTransactingParties } from '../../api';
import { useNavigate } from 'react-router-dom';

const initialState = {
  email: '',
  password: '',
  partyId: 0
};

const LoginScreen = () => {
  const [formData, setFormData] = useState(initialState);
  const [parties, setParties] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getTransactingParties().then(({ data }) => {
      setParties(
        data.map((party) => {
          return {
            key: party.nickname,
            value: party.id
          };
        })
      );
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.partyId == 0) {
      alert('Please select a party');
      return;
    }
    login(formData)
      .then((res) => {
        console.log(res);
        localStorage.setItem('profile', JSON.stringify({ ...res.data }));
        navigate('/');
      })
      .catch((err) => {
        alert('Incorrect Credentials');
      });
  };

  return (
    <Screen>
      <div>
        <img
          style={{ maxWidth: 300, marginTop: 30 }}
          src='/images/compass-full-logo.png'
        />
        <h3 style={{ color: '#02abde' }}>Please Login</h3>
        <form onSubmit={handleSubmit}>
          <InputField
            placeholder='email'
            name='email'
            onChange={handleChange}
          />
          <InputField
            placeholder='password'
            name='password'
            onChange={handleChange}
            type='password'
          />
          <Select
            placeholder='Select Party'
            name='partyId'
            options={parties}
            onChange={handleChange}
          />
          <Button title='Login' type='submit' />
        </form>
      </div>
    </Screen>
  );
};

export default LoginScreen;
