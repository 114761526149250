import React, { useState, useEffect } from 'react';
import Button from '../../../../components/Button/Button';
import ButtonSecondary from '../../../../components/Button/ButtonSecondary';
import InputField from '../../../../components/InputField/InputField';
import SideBar from '../../../../components/SideBar/SideBar';
import qs from 'query-string';
import { Screen, Container } from './styles';
import { Link, useLocation } from 'react-router-dom';
import {
  createTemplate,
  editTemplate,
  getProducts,
  getTemplate as getTemplateCall
} from '../../../../api';

const initialValue = {
  id: '',
  name: '',
  productPrices: []
};

const ManageTemplate = (props) => {
  let e = useLocation();
  const params = qs.parse(e.search);
  const [results, setResults] = useState(initialValue);
  const [productPrices, setProductPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handlePriceChange = (e) => {
    let prices = productPrices;
    const index = prices.findIndex((p) => {
      return p.productId == parseInt(e.target.name);
    });

    prices[index].price = e.target.value;
    setProductPrices(prices);
    setResults({ ...results, productPrices: prices });
  };

  useEffect(() => {
    const getAllProducts = async (template) => {
      const { data } = await getProducts();
      let prices = [];
      let products = template ? template.Product_Prices : [];
      for (let i = 0; i < data.length; i++) {
        const product = data[i];

        const index = products.findIndex((p) => {
          return p.productId == product.id;
        });

        const price = index > -1 ? products[index].price : 0;
        prices.push({ productId: product.id, price, name: product.name });
      }
      setProductPrices(prices);
      setResults({
        id: template?.id,
        name: template?.name,
        productPrices: prices
      });
    };

    const getTemplate = async () => {
      const { data } = await getTemplateCall(params.id);

      getAllProducts(data);
    };

    if (params.id) {
      getTemplate();
    } else {
      getAllProducts();
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (params.id) {
      await editTemplate(params.id, results);
    } else {
      await createTemplate(results);
    }
    setIsLoading(false);
    window.location.href = '/manage/templates';
  };

  return (
    <>
      <Screen>
        <Container>
          <h3>{params.id ? 'Edit' : 'Add'} Price Template</h3>
          <div className='detailInput'>
            <label>Name</label>
            <InputField
              name='name'
              placeholder='Name'
              onChange={handleChange}
              value={results.name}
            />
          </div>
          <hr style={{ width: '100%' }} />
          {productPrices.map((product) => {
            return (
              <div>
                <div className='detailInput'>
                  <label>{product.name}</label>
                  <InputField
                    name={product.productId}
                    placeholder='Price'
                    onChange={handlePriceChange}
                    type='number'
                    value={product.price}
                  />
                </div>
              </div>
            );
          })}
          <div className='buttons'>
            <Link to={`/manage/templates`}>
              <ButtonSecondary title='Cancel' />
            </Link>
            <Button
              disabled={isLoading}
              title={isLoading ? 'Saving...' : params.id ? 'Save' : 'Add'}
              onClick={handleSubmit}
            />
          </div>
        </Container>
      </Screen>
    </>
  );
};

export default ManageTemplate;
