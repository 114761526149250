import React, { useState, useEffect } from 'react';
import SideBar from '../../components/SideBar/SideBar';
import { Screen, TopTile, DashboardHeader, DashboardBody } from './styles';
import { Bar } from 'react-chartjs-2';
import { getData } from '../../api';
import { GetSalesData, topClients, topSites } from './DataHandler';

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Top 5 Clients'
    }
  }
};

const optionsSite = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Top 10 Sites'
    }
  }
};

const initialData = {
  salesThisMonth: 'N/A',
  salesLastMonth: 'N/A',
  projectedSales: 'N/A',
  change: 'N/A',
  annualSales: [],
  topClients: [],
  topSites: [],
  stockOnHand: []
};

const colors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)'
];

const HomeScreen = () => {
  const [dashboardData, setDashboardData] = useState(initialData);

  useEffect(() => {
    getData().then(({ data }) => {
      console.log(data);
      setDashboardData(data);
    });
  }, []);

  const formatMoney = (number) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR'
    }).format(number);
  };

  return (
    <>
      <Screen>
        <DashboardHeader>
          <TopTile>
            <p>This Month Sales</p>
            <p>
              {dashboardData.salesThisMonth
                ? formatMoney(dashboardData.salesThisMonth)
                : 'N/A'}
            </p>
          </TopTile>
          <TopTile>
            <p>Projected Sales</p>
            <p>
              {dashboardData.projectedSales
                ? formatMoney(dashboardData.projectedSales)
                : 'N/A'}
            </p>
          </TopTile>
          <TopTile>
            <p>Last Month Sales</p>
            <p>
              {dashboardData.salesLastMonth
                ? formatMoney(dashboardData.salesLastMonth)
                : 'N/A'}
            </p>
          </TopTile>
          <TopTile>
            <p>% Change</p>
            <p>{dashboardData.change ?? 'N/A'}%</p>
          </TopTile>
        </DashboardHeader>
        <DashboardBody>
          <div>
            <Bar
              data={GetSalesData(
                dashboardData.annualSales,
                dashboardData.projectedSales == 'N/A'
                  ? 0
                  : dashboardData.projectedSales,
                dashboardData.stockOnHand
              )}
              options={{
                scales: {
                  x: {
                    stacked: true
                  },
                  y: {
                    stacked: false
                  }
                }
              }}
            />
          </div>
          <div>
            <div>
              <Bar
                data={topClients(dashboardData.topClients, colors)}
                height={70}
                width={100}
                options={options}
              />
            </div>
            <div>
              <Bar
                data={topSites(
                  dashboardData.topSites,
                  dashboardData.topClients,
                  colors
                )}
                height={70}
                width={100}
                options={optionsSite}
              />
            </div>
          </div>
        </DashboardBody>
      </Screen>
    </>
  );
};

export default HomeScreen;
