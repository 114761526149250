import styled from 'styled-components';

export const StyledSelect = styled.select`
  border: 1px solid #d7e3ec;
  padding: 0.75rem;
  border-radius: 8px;
  text-align: center;
  margin: 20px 5px;
  min-width: 300px;
`;
