import styled from 'styled-components';

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #edf2f5;

  > div:last-child {
    display: flex;
    flex-direction: row;
  }
`;

export const TabScreen = styled.div`
  background-color: white;
  width: 95%;
  border-radius: 8px;
  display: flex;
  flex-direction: column !important;
  padding: 10px;

  .tabsHeader {
    border-bottom: solid 2px #dfdfdf;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
  }

  a {
    text-decoration: none;
  }
`;

export const Tab = styled.div`
  padding: 10px;
  margin: 5px;
  border-radius: 12px;
  min-width: 200px;
  text-decoration: none;
  color: black;

  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }

  &.active {
    background-color: #02abde40;
    color: #02abde;
    font-weight: 700;
  }
`;

export const Table = styled.div`
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    thead {
      background-color: #f3f7f9;
    }

    tr {
      transition: 0.3s;
      &:hover {
        background-color: #f3f7f9;
      }
    }

    th,
    td {
      padding: 15px;
    }
  }
`;
