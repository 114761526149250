import React from 'react';
import './_styles.navigation-frame.scss';
import SideBar from '../SideBar/SideBar';

const NavigationFrame = ({ children }) => {
  const getTitle = () => {
    if (window.location.pathname.includes('add')) {
      if (window.location.pathname.includes('products')) return 'Add Product';
      if (window.location.pathname.includes('parties')) return 'Add Party';
      if (window.location.pathname.includes('sites')) return 'Add Site';
      if (window.location.pathname.includes('templates')) return 'Add Template';
    }

    if (window.location.pathname.includes('edit')) {
      if (window.location.pathname.includes('products')) return 'Edit Product';
      if (window.location.pathname.includes('parties')) return 'Edit Party';
      if (window.location.pathname.includes('sites')) return 'Edit Site';
      if (window.location.pathname.includes('templates'))
        return 'Edit Template';
    }

    if (window.location.pathname.includes('receipt')) return 'Receipts';
    if (window.location.pathname.includes('transfer')) return 'Transfers';
    if (window.location.pathname.includes('sale')) return 'Sales';
    if (window.location.pathname.includes('stock')) return 'Stock Take';
    if (window.location.pathname.includes('report')) return 'Reports';
    if (window.location.pathname.includes('manage')) return 'Manage Business';

    return 'Dashboard';
  };

  return (
    <div className='navigation-frame'>
      <SideBar />
      <div className='content'>
        <h1>{getTitle()}</h1>
        {children}
      </div>
    </div>
  );
};

export default NavigationFrame;
